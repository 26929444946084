@charset "UTF-8";

// Grid
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;


// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Variables

// Colors

$white: #fbfbff;
$grey: #e3e3e3;
$darkgrey: #757575;
$arsenic: #424342;
$dark: #282828;
$black: #000000;
$darkgreen: #244F26;
$forestgreen: #256d1b;
$indiagreen: #149911;
$green: #2bd137;


// Style

.bg-grey {
  background-color: $grey;
}

.bg-black {
  background-color: $black;
}

.bg-darkgrey {
  background-color: $darkgrey;
}

.DateRangePicker__CalendarDatePeriod--am {
  left: 0;
  right: 0; }
.DateRangePicker__CalendarDatePeriod--pm {
  left: 0;
  right: 0; }
