
@media (min-width: 1200px) {
  .legend-wrapper {
    left: 6rem;
  }
}

.legend-wrapper {
  position: absolute;
  left: 5.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border: solid 1px $forestgreen;
  border-radius: 5px;
  text-align: left;
  z-index: 999;
}
