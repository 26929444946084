
.white-square {
  padding-bottom: 7rem;
  width: 85%;
  background-color: $dark;
  min-height: 10rem;

  .subtitle {
    font-size: 1.25rem;
    color: $grey;
    padding: 2rem 0 0 2rem;
  }

  .description {
    color: $grey;
    margin-left: 2rem;
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    min-height: 15rem;
  }
}

.link-img {
  width: 85%;
  margin-top: -6rem;
  margin-left: 15%;
  img {
    width: 100%;
  }
}
