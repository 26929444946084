.icon-box {
  margin-bottom: 2rem;
  .icon {
    @media (min-width: $screen-md-min) {
      width: 50%;
    }
    margin: 2rem 0;
    width: 30vmin;
  }
  .icon-title {
    color: $green;
    font-weight: 600;
  }

  a {
    text-decoration-color: $green;
    .icon-learn {
      color: $darkgreen;
      font-weight: 400;
      &:hover {
        color: $green;

      }
    }
  }
}
