.home-page {
  .fcub-header {
    min-height: 60vmin;
    background-image: url('/assets/fcub-header-img_2.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    @media (min-width: $screen-xl-min) {
      background-position: bottom;
    }
    .fcub-header-text {
      border-radius: 0.5rem;
      min-height: 35vmin;
      margin: 15vmin 0 5vmin;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 2vmin;
      @media (max-width: $screen-xs-max) {
        margin-top: 15vmin;
      }
      .fcub-title {
        font-weight: 700;
        color: $green;
        font-size: 2.8rem;
        margin-bottom: 1vmin;
        @media (max-width: $screen-xs-max) {
          font-size: 2rem;
          margin-bottom: 1vmin;
        }
        @media (min-width: $screen-lg-min) {
          font-size: 3.5rem;
        }
      }
      .fcub-subtitle {
        font-weight: 400;
        color: $grey;
        margin-bottom: 5vmin;
        @media (max-width: $screen-xs-max) {
          font-size: 1.3rem;
          margin-bottom: 5vmin;
        }
      }
      .hashtag-wrapper > a {
        border: 0.2rem solid $green;
        border-radius: 30px;
        background-color: $black, 0.7;
        margin: 0 auto;
        padding: 1.2rem 0.8rem;
        @media (min-width: $screen-md-min) {
          max-width: 40%;
        }
        &:hover {
          background-color: $green;
          color: $white;
        }
        font-weight: 600;
        font-size: 1.6rem;
        color: $green;
        text-decoration-color: $white;
      }
    }
  }
  .iconscience {
    transform: rotate(45deg);
  }
}
