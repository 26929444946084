.footer {
  margin-top: 3rem;
}

.upperfooter {
  background-color: #e3e3e3;
}

.powered {
  margin-bottom: -0.4rem;
  width: 80%;
}

@media (min-width: 992px) {
  .fluidware-logo, .a2a-logo, .mcf88-logo {
    max-width: 315px;
  }
}

.fcub-footer {
  img {
    max-height: 100px;
  }
}

.link-readme {
  color: $white;
  opacity: 0.5;
  text-decoration-color: $white;
  &:hover {
    color: $white;
    opacity: 1;
  }
}

.bg-green-footer {
  background-color: $darkgreen;
}
