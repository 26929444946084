@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.info-button {
  @include button-reset;
  background-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: solid 2px $white;
  border-radius: 50%;
  width: 4vmin;
  height: 4vmin;
  font-size: 1.3rem;
  font-weight: 500;
  position: relative;
  @media (max-width: $screen-md-min) {
    width: 3rem;
    height: 3rem;
  }
  &:hover {
    border: solid 3px $white;
    border-radius: 0.4rem;
    font-weight: 600;
  }
}

.info-button-inactive {
  display: none;
}

.layout-button {
  @include button-reset;
  background-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin: 0 0.3rem;
  width: 5vmin;
  &:hover {
    border: solid 2px $darkgreen;
    border-radius: 0.4rem;
  }
}

.layout-active {
  border: solid 0.2rem $darkgreen;
  border-radius: 0.4rem;
}

@media (min-width:1200px) {
  .twitter-hash {
    font-size: 2rem;
  }
}

.app-inner {
  .top-page {
    padding-top: 6rem;
  }
}

.top-page {
  .select {
    padding: 0 0 1rem 1rem;
    .select-text {
      font-size: 1.3rem;
    }
    select {
      background-color: #fff;
      width: 10rem;
      height: 2rem;
      &:focus {
        outline: none;
      }
    }
  }
  .hashtag-wrapper {
    border: 0.2rem solid $green;
    border-radius: 15px;
    background-color: $black, 0.7;
    margin: 0 auto;
    padding: 0.8rem 0.4rem;
    width: min-content;
    .fcub-hashtags {
      font-weight: 600;
      color: $green;
      margin: 1rem 0.5rem;
      line-height: 1.5rem!important;
      font-size: 1.3rem;
    }
  }
  .icone-layout {
    float: right;
    .grid-button, .list-button {
      width: 2rem;
      height: 2rem;
      margin: 0.1rem;
      border-radius: 0.3rem;
      background-color: #fff;
      &:focus {
        outline: none;
      }
    }
  }
}

.graphs-group {
  .card {
    border: 1px solid $darkgreen;
    .card-header {
      white-space: pre-line;
      padding: 0.75rem 1.25rem;
      .card-header-text {
        display: inline-block;
      }
    }
    .graph-header {
      background-color: $darkgreen;
      color: $white;
    }
    .graph-wrapper {
      position: relative;
      white-space: pre-line;
      padding: 1rem 0;
      overflow: visible;
      .rv-discrete-color-legend-item {
        padding: 0.1rem 0.5rem;
      }
      .rv-xy-plot {
        margin: 0 auto;
        .rv-xy-plot__axis__title {
          text {
          fill: $forestgreen;
          font-size: 1rem;
          }
        }
      }
    }
  }
}
