@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.bg-green {
  background-color: $darkgreen;
  @media (min-width: $screen-md-min) {
    background-color: $darkgreen;
    opacity: 0.9
  }
}

.nav-link, .navbar-brand {
  font-weight: 600;
}

.navbar-button {
  @include button-reset;
  color: $white;
  background-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin: 0 0.2rem;
  border: solid 1px transparent;
  border-radius: 0.4rem;
  padding: 0.5em 1em;
  width: 50px;
  font-weight: 600;
  &:hover {
    text-decoration-color: $white;
    text-decoration: underline;
  }
}

.spento {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.navbar--shown {
  background-color: $darkgreen;
  opacity: 1!important;
  -webkit-transition: top 0.4s;
  -moz-transition: top 0.4s;
  -ms-transition: top 0.4s;
  -o-transition: top 0.4s;
  transition: top 0.4s;


}

.navbar--hidden {
  top: -60px;
  -webkit-transition: top 0.4s;
  -moz-transition: top 0.4s;
  -ms-transition: top 0.4s;
  -o-transition: top 0.4s;
  transition: top 0.4s;
}

.navbar-toggler {
  &:focus {
    outline: none;
  }
}
