.highlight-title {
  border: 2px solid $white;
  width: 3rem;
  margin: 0.3rem 0 2rem;
  &.dark {
    border-color: $dark;
  }
  &.grey {
    border-color: $grey;
  }
  &.green {
     border-color: $green;
  }
  &.green-title {
    border: 3px solid $green;
    width: 4rem
  }
}
